import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Facility } from '../../services/form-controller.service';

interface DropdownOption {
  label: string;
  value: any;
}

export interface ControlFiveProps {
  label: string;
  fieldName: string;
  options: DropdownOption[];
  _globalFieldChangeHandler: (e: any) => void;
  _currentFormData?: any;
  _facility?: Facility;
  _costCallback: any;
  _disabled: boolean;
}

const ControlFive: React.FC<ControlFiveProps> = ({
  label,
  fieldName,
  options,
  _globalFieldChangeHandler,
  _currentFormData,
  _costCallback,
  _facility,
  _disabled
}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [_data, setData] = useState<any>({});

  const handleCheckboxChange = (e: any) => {
    setIsEnabled(e.target.checked);
    _globalFieldChangeHandler(e);

    if(e.target.checked === false){
      setData({ ..._data, [`${fieldName}_OPTION`]: null });
    }
    
    _costCallback({
      selected: e.target.checked,
      name: _facility?.facility,
      category: _facility?.category,
      selectedOption: _data[`${fieldName}_OPTION`],
      valid: _data[`${fieldName}_OPTION`] !== '' && _data[`${fieldName}_OPTION`] !== undefined
    })
  };

  const _handleChange = (e: any) => {
    setData({ ..._data, [e.target.id]: e.target.value });
    _globalFieldChangeHandler(e);
    _costCallback({
      selected: true,
      name: _facility?.facility,
      category: _facility?.category,
      selectedOption: e.target.value,
      valid: e.target.value !== '' && e.target.value !== undefined 
    })
  };

  useEffect(() => {
    if (_currentFormData) {
      setData(_currentFormData);
      if(_currentFormData[fieldName] !== undefined){
        // console.log(_currentFormData[fieldName])
        setIsEnabled(_currentFormData[fieldName]);
      }
    }
   
  }, []);

  return (
    <div>
      <div style={{ display: 'flex', marginBottom: '1rem' }}>
        <div style={{ width: '40%' }}>
          <Checkbox
          disabled={_disabled}
            checked={isEnabled}
            id={`${fieldName}`}
            onChange={handleCheckboxChange}
            style={{ marginRight: '1rem' }}
          />
          <label htmlFor={`${fieldName}_OPTION`}>{label}:</label>
        </div>
        <div style={{ width: '60%' }}>
          <Dropdown
            id={`${fieldName}_OPTION`}
            options={options}
            onChange={_handleChange}
            value={_data[`${fieldName}_OPTION`]}
            disabled={!isEnabled}
          />
        </div>
      </div>
    </div>
  );
};

export default ControlFive;
