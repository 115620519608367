import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import FormControllerService from '../services/form-controller.service';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';


export interface SectionFourProps {
  formControllerService: FormControllerService; // this service will manage all values from the inputs
}
const FormSectionFour = (props: SectionFourProps) => {

  const [_currentFormData, setCurrentFormData] = useState<any>();
  const [focusedFields, setFocusedFields] = useState(new Set());
  const [isDisabled, setIsDisabled] = useState(false);


  const handleFocus = (field: any) => {
    setFocusedFields((prev) => new Set(prev).add(field));
  };
  const _globalFieldChangeHandler = (e: any) => {

    // console.log(e)
    // get value and id of changing element
    const _id = e.target.id || e.id;
    let _value = e.target.value || e.value;
    if(e.hasOwnProperty('checked')){
      _value = e.checked
    }
    setCurrentFormData({
      ..._currentFormData, [_id]: _value
    })
    props.formControllerService.validateField(_id,_value);
    props.formControllerService.setFormData(_id as any, _value);

  };
  const _unpackPreviousSession = () => {
   
    setCurrentFormData({
      ...props.formControllerService._formData    
    })

  }
  useEffect(()=>{
    _unpackPreviousSession();
    if((props.formControllerService._READ_ONLY && props.formControllerService._CURRENT_ROLE !== 'ADMIN') || props.formControllerService.getSession()?.status == 'COMPLETED_APPROVED'){
      setIsDisabled(true)
    }

    
  }, [])

  return (
<div style={{ width: '100%', padding: 15, height: '100%' }}>
  <div className="p-fluid">
    <h3>Informatie Overledene</h3>

    <div className="p-grid">
      <div className="p-col-12 p-md-6">
        <div style={{ display: 'flex' }} className="padding">
          <div style={{ width: '50%' }}>
            <label htmlFor="bsn_nummer_overledene">BSN nummer:</label>
          </div>
          <div style={{ width: '40%' }}>
            <InputText
               disabled={isDisabled}  id="bsn_nummer_overledene"
              onChange={_globalFieldChangeHandler}
              value={_currentFormData?.bsn_nummer_overledene}
              onFocus={() => handleFocus('bsn_nummer_overledene')}
            />
          </div>
          <div style={{ width: '10%', margin: 'auto', marginLeft: 10, marginTop: 5 }}>
            {(focusedFields.has('bsn_nummer_overledene') || _currentFormData?.bsn_nummer_overledene) && (
              <i
                className={`pi ${
                  props.formControllerService.validateField(
                    'bsn_nummer_overledene',
                    _currentFormData?.bsn_nummer_overledene
                  )
                    ? 'pi-check green'
                    : 'pi-times red'
                }`}
              />
            )}
          </div>
        </div>

        <div style={{ display: 'flex' }} className="padding">
          <div style={{ width: '50%' }}>
            <label htmlFor="achternaam_overledene">Achternaam:</label>
          </div>
          <div style={{ width: '40%' }}>
            <InputText
               disabled={isDisabled}  id="achternaam_overledene"
              onChange={_globalFieldChangeHandler}
              value={_currentFormData?.achternaam_overledene}
              onFocus={() => handleFocus('achternaam_overledene')}
            />
          </div>
          <div style={{ width: '10%', margin: 'auto', marginLeft: 10, marginTop: 5 }}>
            {(focusedFields.has('achternaam_overledene') || _currentFormData?.achternaam_overledene) && (
              <i
                className={`pi ${
                  props.formControllerService.validateField(
                    'achternaam_overledene',
                    _currentFormData?.achternaam_overledene
                  )
                    ? 'pi-check green'
                    : 'pi-times red'
                }`}
              />
            )}
          </div>
        </div>

        <div style={{ display: 'flex' }} className="padding">
          <div style={{ width: '50%' }}>
            <label htmlFor="voornamen_letters_overledene">Voornamen / letters:</label>
          </div>
          <div style={{ width: '40%' }}>
            <InputText
               disabled={isDisabled}  id="voornamen_letters_overledene"
              onChange={_globalFieldChangeHandler}
              value={_currentFormData?.voornamen_letters_overledene}
              onFocus={() => handleFocus('voornamen_letters_overledene')}
            />
          </div>
          <div style={{ width: '10%', margin: 'auto', marginLeft: 10, marginTop: 5 }}>
            {(focusedFields.has('voornamen_letters_overledene') || _currentFormData?.voornamen_letters_overledene) && (
              <i
                className={`pi ${
                  props.formControllerService.validateField(
                    'voornamen_letters_overledene',
                    _currentFormData?.voornamen_letters_overledene
                  )
                    ? 'pi-check green'
                    : 'pi-times red'
                }`}
              />
            )}
          </div>
        </div>

        <div style={{ display: 'flex' }} className="padding">
          <div style={{ width: '50%' }}>
            <label htmlFor="geslacht_overledene">Geslacht:</label>
          </div>
          <div style={{ width: '40%' }}>
            <Dropdown
               disabled={isDisabled}  id="geslacht_overledene"
              options={[{ label: 'Man', value: 'man' }, { label: 'Vrouw', value: 'vrouw' }]}
              onChange={_globalFieldChangeHandler}
              value={_currentFormData?.geslacht_overledene}
              onFocus={() => handleFocus('geslacht_overledene')}
            />
          </div>
          <div style={{ width: '10%', margin: 'auto', marginLeft: 10, marginTop: 5 }}>
            {(focusedFields.has('geslacht_overledene') || _currentFormData?.geslacht_overledene) && (
              <i
                className={`pi ${
                  props.formControllerService.validateField(
                    'geslacht_overledene',
                    _currentFormData?.geslacht_overledene
                  )
                    ? 'pi-check green'
                    : 'pi-times red'
                }`}
              />
            )}
          </div>
        </div>

        <div style={{ display: 'flex' }} className="padding">
          <div style={{ width: '50%' }}>
            <label htmlFor="geboortedatum_overledene">Geboortedatum:</label>
          </div>
          <div style={{ width: '40%' }}>
            <InputMask
              mask="99-99-9999"
               disabled={isDisabled}  id="geboortedatum_overledene"
              onChange={_globalFieldChangeHandler}
              value={_currentFormData?.geboortedatum_overledene}
              onFocus={() => handleFocus('geboortedatum_overledene')}
            />
          </div>
          <div style={{ width: '10%', margin: 'auto', marginLeft: 10, marginTop: 5 }}>
            {(focusedFields.has('geboortedatum_overledene') || _currentFormData?.geboortedatum_overledene) && (
              <i
                className={`pi ${
                  props.formControllerService.validateField(
                    'geboortedatum_overledene',
                    _currentFormData?.geboortedatum_overledene
                  )
                    ? 'pi-check green'
                    : 'pi-times red'
                }`}
              />
            )}
          </div>
        </div>

        <div style={{ display: 'flex' }} className="padding">
          <div style={{ width: '50%' }}>
            <label htmlFor="geboorteplaats_overledene">Geboorteplaats:</label>
          </div>
          <div style={{ width: '40%' }}>
            <InputText
               disabled={isDisabled}  id="geboorteplaats_overledene"
              onChange={_globalFieldChangeHandler}
              value={_currentFormData?.geboorteplaats_overledene}
              onFocus={() => handleFocus('geboorteplaats_overledene')}
            />
          </div>
          <div style={{ width: '10%', margin: 'auto', marginLeft: 10, marginTop: 5 }}>
            {(focusedFields.has('geboorteplaats_overledene') || _currentFormData?.geboorteplaats_overledene) && (
              <i
                className={`pi ${
                  props.formControllerService.validateField(
                    'geboorteplaats_overledene',
                    _currentFormData?.geboorteplaats_overledene
                  )
                    ? 'pi-check green'
                    : 'pi-times red'
                }`}
              />
            )}
          </div>
        </div>

        <div style={{ display: 'flex' }} className="padding">
          <div style={{ width: '50%' }}>
            <label htmlFor="geboorteland_overledene">Geboorteland:</label>
          </div>
          <div style={{ width: '40%' }}>
            <InputText
               disabled={isDisabled}  id="geboorteland_overledene"
              onChange={_globalFieldChangeHandler}
              value={_currentFormData?.geboorteland_overledene}
              onFocus={() => handleFocus('geboorteland_overledene')}
            />
          </div>
          <div style={{ width: '10%', margin: 'auto', marginLeft: 10, marginTop: 5 }}>
            {(focusedFields.has('geboorteland_overledene') || _currentFormData?.geboorteland_overledene) && (
              <i
                className={`pi ${
                  props.formControllerService.validateField(
                    'geboorteland_overledene',
                    _currentFormData?.geboorteland_overledene
                  )
                    ? 'pi-check green'
                    : 'pi-times red'
                }`}
              />
            )}
          </div>
        </div>

        <div style={{ display: 'flex' }} className="padding">
          <div style={{ width: '50%' }}>
            <label htmlFor="naam_partner_overledene">Naam partner:</label>
          </div>
          <div style={{ width: '40%' }}>
            <InputText
               disabled={isDisabled}  id="naam_partner_overledene"
              onChange={_globalFieldChangeHandler}
              value={_currentFormData?.naam_partner_overledene}
              onFocus={() => handleFocus('naam_partner_overledene')}
            />
          </div>
          <div style={{ width: '10%', margin: 'auto', marginLeft: 10, marginTop: 5 }}>
            {(focusedFields.has('naam_partner_overledene') || _currentFormData?.naam_partner_overledene) && (
              <i
                className={`pi ${
                  props.formControllerService.validateField(
                    'naam_partner_overledene',
                    _currentFormData?.naam_partner_overledene
                  )
                    ? 'pi-check green'
                    : 'pi-times red'
                }`}
              />
            )}
          </div>
        </div>

        <div style={{ display: 'flex' }} className="padding">
          <div style={{ width: '50%' }}>
            <label htmlFor="laatste_woonplaats_overledene">Laatste woonplaats:</label>
          </div>
          <div style={{ width: '40%' }}>
            <InputText
               disabled={isDisabled}  id="laatste_woonplaats_overledene"
              onChange={_globalFieldChangeHandler}
              value={_currentFormData?.laatste_woonplaats_overledene}
              onFocus={() => handleFocus('laatste_woonplaats_overledene')}
            />
          </div>
          <div style={{ width: '10%', margin: 'auto', marginLeft: 10, marginTop: 5 }}>
            {(focusedFields.has('laatste_woonplaats_overledene') || _currentFormData?.laatste_woonplaats_overledene) && (
              <i
                className={`pi ${
                  props.formControllerService.validateField(
                    'laatste_woonplaats_overledene',
                    _currentFormData?.laatste_woonplaats_overledene
                  )
                    ? 'pi-check green'
                    : 'pi-times red'
                }`}
              />
            )}
          </div>
        </div>

        <div style={{ display: 'flex' }} className="padding">
          <div style={{ width: '50%' }}>
            <label htmlFor="datum_overlijden">Datum overlijden:</label>
          </div>
          <div style={{ width: '40%' }}>
            <InputMask
              mask="99-99-9999"
               disabled={isDisabled}  
               id="datum_overlijden"
              onChange={_globalFieldChangeHandler}
              value={_currentFormData?.datum_overlijden}
              onFocus={() => handleFocus('datum_overlijden')}
            />
          </div>
          <div style={{ width: '10%', margin: 'auto', marginLeft: 10, marginTop: 5 }}>
            {(focusedFields.has('datum_overlijden') || _currentFormData?.datum_overlijden) && (
              <i
                className={`pi ${
                  props.formControllerService.validateField(
                    'datum_overlijden',
                    _currentFormData?.datum_overlijden
                  )
                    ? 'pi-check green'
                    : 'pi-times red'
                }`}
              />
            )}
          </div>
        </div>

        <div style={{ display: 'flex' }} className="padding">
          <div style={{ width: '50%' }}>
            <label htmlFor="gemeente_overlijden">Gemeente:</label>
          </div>
          <div style={{ width: '40%' }}>
            <InputText
               disabled={isDisabled}  id="gemeente_overlijden"
              onChange={_globalFieldChangeHandler}
              value={_currentFormData?.gemeente_overlijden}
              onFocus={() => handleFocus('gemeente_overlijden')}
            />
          </div>
          <div style={{ width: '10%', margin: 'auto', marginLeft: 10, marginTop: 5 }}>
            {(focusedFields.has('gemeente_overlijden') || _currentFormData?.gemeente_overlijden) && (
              <i
                className={`pi ${
                  props.formControllerService.validateField(
                    'gemeente_overlijden',
                    _currentFormData?.gemeente_overlijden
                  )
                    ? 'pi-check green'
                    : 'pi-times red'
                }`}
              />
            )}
          </div>
        </div>

        <div style={{ display: 'flex' }} className="padding">
          <div style={{ width: '50%' }}>
            <label htmlFor="land_overlijden">Land:</label>
          </div>
          <div style={{ width: '40%' }}>
            <InputText
               disabled={isDisabled}  id="land_overlijden"
              onChange={_globalFieldChangeHandler}
              value={_currentFormData?.land_overlijden}
              onFocus={() => handleFocus('land_overlijden')}
            />
          </div>
          <div style={{ width: '10%', margin: 'auto', marginLeft: 10, marginTop: 5 }}>
            {(focusedFields.has('land_overlijden') || _currentFormData?.land_overlijden) && (
              <i
                className={`pi ${
                  props.formControllerService.validateField(
                    'land_overlijden',
                    _currentFormData?.land_overlijden
                  )
                    ? 'pi-check green'
                    : 'pi-times red'
                }`}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  );
};

export default FormSectionFour;
