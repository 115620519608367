import React, { useEffect, useRef, useState } from "react";
import { Stepper, StepperChangeEvent } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { Button } from "primereact/button";
import FormSectionOne from "../form-sections/section-one";
import FormSectionTwo from "../form-sections/section-two";
import FormSectionThree from "../form-sections/section-three";
import FormSectionFour from "../form-sections/section-four";
import FormSectionFive from "../form-sections/section-five";
import FormSectionSix from "../form-sections/section-six";
import FormSectionSeven from "../form-sections/section-seven";
import ClientDataService, {
  SessionObject,
} from "../services/client-data-service";
import { ProgressSpinner } from "primereact/progressspinner";
import { v4 as uuidv4 } from "uuid";
import FormControllerService, {
  Cost,
  FuneralType,
  Location,
} from "../services/form-controller.service";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { exec } from "child_process";

type Props = {};

export interface FormSectionData {
  section: string;
  complete: boolean;
  formControllerService: FormControllerService;
}

export interface ControlDataMap {
  HOLIDAYS: any[];
  LOCATIONS: Location[];
  COSTS: any[];
  FACILITIES: any[];
  CHAMBERNUMBERS: any[];
  FUNERALTYPES: FuneralType[];
}

function FormController({}: Props) {
  const stepperRef = useRef(null);
  const [_currentStep, setCurrentStep] = useState<number>(0);
  const [_completed, setCompleted] = useState<boolean>(false);
  const [_completeMessage, setCompleteMessage] = useState<string>("Formulier is succesvol ingediend");
  // submitDialogVisible
  const [submitDialogVisible,  setSubmitDialogVisiblee] = useState<boolean>(false);
  const [warningDialogVisible,  setWarningDialogVisible] = useState<boolean>(false);
  const [facilCostsdialogVisible, setFacilCostsdialogVisible] = useState<boolean>(false);
  const _dataService = new ClientDataService("https://westland.liminil.net");
  const [_formControllerService, setFormControllerService] =
    useState<FormControllerService>(new FormControllerService());

  const [message, setMessage] = useState<string>(
    ""
  );
  const [controlDataMap, setControlDataMap] = useState<any>({});
  const [facilCosts, setFacilCosts] = useState<any[]>();
  const [currentSession, setCurrentSession] = useState<SessionObject>();
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [saveDialogVisible, setSaveDialogVisible] = useState<boolean>(false);
  const [hasPreviousSession, setHasPreviousSession] = useState<boolean>(false);
  const [_costs, setCosts] = useState<Cost[]>([]);
  const defaultSession: SessionObject = {
    id: uuidv4(),
    formData: _formControllerService?.getEncryptedFormData() as string,
    status: "NEW",
    adminEmail: "",
    userEmail: "",
    createdOn: new Date().toDateString(),
    directorSignature: "",
    adminSignature: "",
    selectedFacilities: "[]",
    burialCosts:"[]"
  };

  const fetchControlData = async () => {
    try {
      const cMap = await _dataService.getControlData();
      _formControllerService?.setControlData(cMap);
      setControlDataMap(cMap);
    } catch (e: any) {
      console.log("error fetching control data");
    }
  };

  const checkUrlParams = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let uuid = urlParams.get("session");
    let key = urlParams.get("key");

    if (!uuid && !currentSession) {
      // this is a new session
      setCurrentSession(defaultSession as SessionObject);
      _formControllerService?.setSession(defaultSession, true);
      _formControllerService._newSession = true;
    } else if (uuid) {
       // this is an existing session
      try {
        setHasPreviousSession(true);
        // lets load the existing session
        const prevSession = await _dataService.getFormData(uuid);
        if(prevSession.id == atob(key as string)){
          _formControllerService.setCurrentRole('ADMIN')
        } else {
          console.log('wrong key');
          console.log(_formControllerService._READ_ONLY);
        }
        setCurrentSession(prevSession as SessionObject);
        _formControllerService?.setSession(prevSession);
        _formControllerService?.setInProgressFormData(prevSession.formData);
      } catch (e) {
        console.log(e);
      }
    }
    fetchControlData();
  };

  const getRowClass = (rowData: any) => {
    return rowData.type === "Subtotaal" ? "bold-row" : "";
  };

  const getRowClassFacil = (rowData: any) => {
    return rowData.category === "Subtotaal" ? "bold-row" : "";
  };

  useEffect(() => {
    checkUrlParams();
  }, []);

  const _sendForm = async () => {
    if(_formControllerService.getSession()?.directorSignature == ''){
      setMessage("U moet het formulier eerst ondertekenen voordat u het verzendt");
      setWarningDialogVisible(true)

    } else {

    if(_formControllerService.pageOneValid() && _formControllerService.pageThreeValid() && _formControllerService.pageFourValid() && _formControllerService.pageSixValid()){
      setSubmitDialogVisiblee(false);
      setCompleted(true);
      let formData = _formControllerService?.getEncryptedFormData();
      let sess = _formControllerService.getSession();
      const _currentSession = {
        ...sess,
        status: "INPROGRESS_SUBMITTED",
        formData,
        selectedFacilities: btoa(JSON.stringify(_formControllerService.getSelectedFacilities()))
      } as SessionObject;
  
      _dataService.updateFormData(_currentSession as SessionObject);
  
    } else { 
      console.log(`page 1 results ${_formControllerService.pageOneValid()}`);
      console.log(`page 3 results ${_formControllerService.pageThreeValid()}`);
      console.log(`page 4 results ${_formControllerService.pageFourValid()}`);
      console.log(`page 6 results ${_formControllerService.pageSixValid()}`);
      
      setMessage("Formulier is nog niet helemaal ingevuld.");
      setWarningDialogVisible(true);
  }
  }
  }
  const _showSendDialog = () => {
    setSubmitDialogVisiblee(true)
  }
  const _approveForm = async () => {

    if(_formControllerService.getSession()?.adminSignature == ''){
      setMessage("U moet het formulier eerst ondertekenen voordat u het verzendt");
      setWarningDialogVisible(true)

    } else {
    
    let formData = _formControllerService?.getEncryptedFormData();
    let sess = currentSession;
    const _currentSession = {
      ...sess,
      status: "COMPLETED_APPROVED",
      formData,
      selectedFacilities: btoa(JSON.stringify(_formControllerService.getSelectedFacilities()))
    } as SessionObject;

    _dataService.updateFormData(_currentSession as SessionObject);
    setCompleted(true);
    setCompleteMessage("Aanvraag doorgezet naar administratie");
    // window.location.href = "https://westland.liminil.net";

  }
  }
  const _executeSave = async (mode?:string) => {

    const newStatus = mode == 'with-email' ? 'INPROGRESS_EMAIL' : 'INPROGRESS';
    let formData = _formControllerService?.getEncryptedFormData();
    _formControllerService?.setSession({...currentSession, status: newStatus} as SessionObject);
    let sess = _formControllerService.getSession();
    const _currentSession = {
      ...sess,
      status: newStatus,
      formData,
      selectedFacilities: btoa(JSON.stringify(_formControllerService.getSelectedFacilities()))
      // ,directorSignature: '',adminSignature: ''
    } as SessionObject;

    if(!hasPreviousSession){
        setHasPreviousSession(true);
        _dataService.postFormData(_currentSession as SessionObject);

    } else {
        _dataService.updateFormData(_currentSession as SessionObject);

    }

    setSaveDialogVisible(false);
  };
  const _handleSaveForm = () => {
    if (_formControllerService.validateField('ondernemer_email', _formControllerService._formData.ondernemer_email)) {
      setSaveDialogVisible(true);
    } else {
      setMessage("Om op te slaan moet u een geldig e-mailadres invoeren");
      setWarningDialogVisible(true)
    }
  };



  // a function to scroll to the top of the page
  const _scrollToTop = () => {
    window.scrollTo(0, 0);
  };


  const renderPrice = (cost: any) => {
    if (!cost.hasOwnProperty('totalCost') && cost.hasOwnProperty('cost')) {
      return `${cost.cost.replaceAll('.', ',').replaceAll(' ', '.')}`;
    } else if(cost.hasOwnProperty('totalCost')){
      const totalCost = cost.totalCost;
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    
      return formatter.format(totalCost);
    } else {
      return ''
    }
  

  };

  const _moveForward = () => {
    // console.log(_formControllerService._selectedFacilities)
    const { _formData, _CURRENT_ROLE } = _formControllerService;
    switch ((stepperRef.current as any).getActiveStep()) {
      case 0:
        if (_formControllerService.pageOneValid()) {
          _scrollToTop();
          setCurrentStep(1);
          (stepperRef.current as any).setActiveStep(1)
        } else {
          setMessage("Formulier is nog niet helemaal ingevuld.");
          setWarningDialogVisible(true);
        }
        break;
  
      case 1:
        if (_formData.rightsType === 'n') {
          _scrollToTop();
          setCurrentStep(3);
          (stepperRef.current as any).setActiveStep(3)
        } else if (_formData.rightsType === 'b' || _formData.rightsType === 'r') {
          (stepperRef.current as any).setActiveStep(2)
          setCurrentStep(2);
        }
        break;
  
      case 2:
        if (_formControllerService.pageThreeValid()) {
          _scrollToTop();
          setCurrentStep(3);
          (stepperRef.current as any).setActiveStep(3)
        } else {
          setMessage("Formulier is nog niet helemaal ingevuld.");
          setWarningDialogVisible(true);
        }
        break;
  
      case 3:
        if(_formControllerService.pageFourValid()){
          _scrollToTop();
          setCurrentStep(4);
          (stepperRef.current as any).setActiveStep(4)
        } else {
          setMessage("Formulier is nog niet helemaal ingevuld.");
          setWarningDialogVisible(true);
        }
       
        break;
  
      case 4:

          let _check = true;
          _formControllerService._selectedFacilities.forEach((each: any) => {
            if (each.selected) {
              if (!each.valid) _check = false;
            }
          });
          if(_check){
            _scrollToTop();
            (stepperRef.current as any).setActiveStep(5);
            setCurrentStep(5);
          } else {
            setMessage("Formulier is nog niet helemaal ingevuld.");
            setWarningDialogVisible(true);
          }
       
        break;
  
      case 5:
        if(_formControllerService.pageSixValid()){
          _scrollToTop();
            (stepperRef.current as any).setActiveStep(6);
            setCurrentStep(6);
        } else {
          setMessage("Formulier is nog niet helemaal ingevuld.");
          setWarningDialogVisible(true);
        }
          
       
        
        break;
      case 6:
        if (_CURRENT_ROLE === 'ADMIN') {
          _scrollToTop();
          (stepperRef.current as any).setActiveStep(7)
          setCurrentStep(7);
        }
        break;
  
      default:
        break;
    }
  };

  const _moveBackward = () => {
    // console.log(_formControllerService._selectedFacilities)
    const { _formData, _CURRENT_ROLE } = _formControllerService;
    // console.log((stepperRef.current as any).getActiveStep())
    switch ((stepperRef.current as any).getActiveStep()) {
      case 1:
        setCurrentStep(0);
        (stepperRef.current as any).setActiveStep(0)
        break;
  
      case 2:
        setCurrentStep(1);
        (stepperRef.current as any).setActiveStep(1)
        break;
  
      case 3:
        if (_formData.rightsType === 'b' || _formData.rightsType === 'r') {
          (stepperRef.current as any).setActiveStep(2)
          setCurrentStep(2);
        }else {
          (stepperRef.current as any).setActiveStep(1)
          setCurrentStep(1);
        }
        break;
  
      case 4:
        let _check = true;
        _formControllerService._selectedFacilities.forEach((each: any) => {
          if (each.selected) {
            if (!each.valid) _check = false;
          }
        });

        if(_check){
        setCurrentStep(3);
        (stepperRef.current as any).setActiveStep(3)
        } else {
          setMessage("Formulier is nog niet helemaal ingevuld.");
          setWarningDialogVisible(true);
        }
        
        break;
  
      case 5:
        setCurrentStep(4);
        (stepperRef.current as any).setActiveStep(4)
        break;
  
      case 6:
        setCurrentStep(5);
        (stepperRef.current as any).setActiveStep(5)
        break;


  
      default:
        break;
    }
  }
  
  const _handleStepChange = (e: StepperChangeEvent) => {
    // console.log(e.index)
    const _currentStep = (stepperRef.current as any).getActiveStep();
    // switch(e.index){
    //   case 0:
    //     if((stepperRef.current as any).getActiveStep() == 1){
    //       setCurrentStep(0);
    //     } else {
    //       (stepperRef.current as any).setActiveStep(_currentStep)
    //     }

    //     break;
    // }
    // // setCurrentStep(e.index);
  }


  return (
    <div style={{ width: "75%", margin: "auto"}}>
    {_completed ? <div style={{width: '100%', height: '300px',marginTop: '120px', backgroundColor: 'white', padding: '20px'}} className="alert alert-success"><h1>{_completeMessage}</h1></div> : null}
     {!_completed ?  
     <div style={{marginTop: 100}}>
      <Stepper ref={stepperRef} linear={_formControllerService._session?.status != 'INPROGRESS_EMAILED'} activeStep={_currentStep} onChangeStep={_handleStepChange}>
        <StepperPanel>
        <div className="flex justify-content-start">
            
            <Button
              label="Volgende"
              className="navButtons"
              icon="pi pi-arrow-right"
              iconPos="right"
              onClick={_moveForward}
            />
            <Button
              label="Opslaan" 
              className="navButtons"
              icon="pi pi-save"
              iconPos="right"
              onClick={_handleSaveForm}
            />
          </div>
          <div className="flex flex-column h-12rem" style={{ minHeight: 825, marginTop: '10px' }}>
            <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
              {Object.keys(controlDataMap).length == 7 &&
              _formControllerService ? (
                <FormSectionOne
                  formControllerService={_formControllerService}
                />
              ) : (
                <ProgressSpinner />
              )}
            </div>
          </div>

        </StepperPanel>

        <StepperPanel>
        <div className="flex justify-content-start">
           
           <Button
             label="Vorige"
             className="navButtons"
             severity="secondary"
             icon="pi pi-arrow-left"
             onClick={_moveBackward}
           />
           <Button
             label="Volgende"
             className="navButtons"
             icon="pi pi-arrow-right"
             iconPos="right"
             onClick={_moveForward}
           />
           <Button
             label="Opslaan" 
             className="navButtons"
             icon="pi pi-save"
             iconPos="right"
             onClick={_handleSaveForm}
           />
         </div>

          <div className="flex flex-column h-12rem" style={{ minHeight: 825, marginTop: '10px' }}>
            <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
           
               <FormSectionTwo 
                  formControllerService={_formControllerService}
              />
            </div>
          </div>

        </StepperPanel>

        <StepperPanel>
        <div className="flex justify-content-start">
        
        <Button
          label="Vorige"
          className="navButtons"
          severity="secondary"
          icon="pi pi-arrow-left"
          onClick={_moveBackward}
        />
        <Button
          label="Volgende"
          className="navButtons"
          icon="pi pi-arrow-right"
          iconPos="right"
          onClick={_moveForward}
        />
        <Button
          label="Opslaan" 
          className="navButtons"
          icon="pi pi-save"
          iconPos="right"
          onClick={_handleSaveForm}
        />
      </div>

          <div className="flex flex-column h-12rem" style={{ minHeight: 825, marginTop: '10px' }}>
            <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
            {/* {rightsType == 'r' || rightsType == 'b' ? <FormSectionThree formControllerService={_formControllerService}/> :  <FormSectionFive  formControllerService={_formControllerService}/>} */}
             <FormSectionThree formControllerService={_formControllerService}/>
            </div>
          </div>

        </StepperPanel>

        <StepperPanel>
        <div className="flex justify-content-start">
         
         <Button
           label="Vorige"
           className="navButtons"
           severity="secondary"
           icon="pi pi-arrow-left"
           onClick={_moveBackward}
         />
         <Button
           label="Volgende"
           className="navButtons"
           icon="pi pi-arrow-right"
           iconPos="right"
           onClick={_moveForward}
         />
         <Button
           label="Opslaan" 
           className="navButtons"
           icon="pi pi-save"
           iconPos="right"
           onClick={_handleSaveForm}
         />
       </div>

          <div className="flex flex-column h-12rem" style={{ minHeight: 825, marginTop: '10px' }}>
            <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
              <FormSectionFour formControllerService={_formControllerService} /> 
            </div>
          </div>

        </StepperPanel>

        <StepperPanel>
        <div className="flex justify-content-start">

<Button
  label="Vorige"
  className="navButtons"
  severity="secondary"
  icon="pi pi-arrow-left"
  onClick={_moveBackward}
/>
<Button
  label="Volgende"
  className="navButtons"
  icon="pi pi-arrow-right"
  iconPos="right"
  onClick={_moveForward}
/>
<Button
  label="Opslaan" 
  className="navButtons"
  icon="pi pi-save"
  iconPos="right"
  onClick={_handleSaveForm}
/>
</div>
          <div className="flex flex-column h-12rem" style={{ minHeight: 850, marginTop:'10px' }}>
            <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
            <FormSectionFive formControllerService={_formControllerService} />
            </div>
          </div>

        </StepperPanel>

        <StepperPanel>
        <div className="flex justify-content-start">
            <Button
              className="navButtons"
              label="Vorige"
              severity="secondary"
              icon="pi pi-arrow-left"
              onClick={_moveBackward}
            />
            {_formControllerService.getCurrentRole() === 'ADMIN' ? <Button
              label="Volgende"
              className="navButtons"
              icon="pi pi-arrow-right"
              iconPos="right"
              onClick={_moveForward}
            /> : null}
            &nbsp;
            <Button
              label="Opslaan" 
              className="navButtons"
              icon="pi pi-save"
              iconPos="right"
              onClick={_handleSaveForm}
            />&nbsp;
            <Button
            visible={_formControllerService.getSession()?.status != 'INPROGRESS_SUBMITTED' || _formControllerService.getSession()?.status != 'COMPLETED_APPROVED'|| _formControllerService.getSession()?.status != 'COMPLETED_ARCHIVED'}
            label="Aanvraag indienen"
            style={{ backgroundColor: "#44772C" }}
            onClick={_sendForm}
            />
          </div>

          <div className="flex flex-column h-12rem" style={{ minHeight: 825, marginTop: '10px' }}>
            <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
           <FormSectionSix  _sendFormCallback={_showSendDialog}  formControllerService={_formControllerService}/>
            </div>
          </div>

          
        </StepperPanel>

        <StepperPanel>

        <div className="flex justify-content-start">
            <Button
              className="navButtons"
              label="Vorige"
              severity="secondary"
              icon="pi pi-arrow-left"
              onClick={_moveBackward}
            />
            <Button
              label="Opslaan" 
              className="navButtons"
              icon="pi pi-save"
              iconPos="right"
              onClick={_handleSaveForm}
            />
            &nbsp;
            <Button
                label="Formulier versturen naar administratie"
                className="p-button-success"
                onClick={_approveForm}
                style={{ backgroundColor: "#44772C" }}
              />
          </div>
          <div className="flex flex-column h-12rem" style={{ minHeight: 825, marginTop: '10px' }}>
            <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
              <FormSectionSeven formControllerService={_formControllerService} _approveFormCallback={_approveForm}/>
            </div>
          </div>

        </StepperPanel>
      </Stepper>
     </div> : null}

      {/* <input
        value={`https://westland.liminil.net?session=${currentSession?.id}`}
        type="text"
        disabled
        id="cacheUrl"
        style={{ width: "50%" }}
      /> */}

      <Dialog
        header="Kosten Begraven"
        visible={dialogVisible}
        style={{ width: "75vw" }}
        maximizable
        modal
        contentStyle={{ height: "600px" }}
        onHide={() => setDialogVisible(false)}
      >
        {_costs.length > 0 ? (
          <DataTable value={_costs} tableStyle={{ minWidth: "50rem" }} rowClassName={getRowClass}>
            {Object.keys((_costs as any)[0] as any)
              .filter((eachOne) => {
                return (
                  eachOne !== "id" &&
                  eachOne !== "_id" &&
                  eachOne !== "burial_type"
                );
              })
              .map((eachKey) => {
                if(eachKey == 'cost'){
                  return <Column
                  key={eachKey}
                  field={eachKey}
                  body={renderPrice}
                  style={{textAlign: 'right'}}
                  
                />
                } else {
                  return <Column
                  key={eachKey}
                  field={eachKey}
                />
                }
                
              })}
          </DataTable>
        ) : null}
      </Dialog>

      <Dialog
        header="Kosten Facilities"
        visible={facilCostsdialogVisible}
        style={{ width: "75vw" }}
        maximizable
        maximized
        modal
        contentStyle={{ height: "600px" }}
        onHide={() => setFacilCostsdialogVisible(false)}
      >
        {facilCosts && facilCosts.length > 0 ? (
          <DataTable value={facilCosts} tableStyle={{ minWidth: "50rem" }} rowClassName={getRowClassFacil}>
            {Object.keys((facilCosts as any)[0] as any)
              .map((eachKey) => {
                if(eachKey !== 'relatedDates' && eachKey !== 'relatedDateAndTimes' && eachKey !== 'selected'){
                  if(eachKey == 'totalCost'){
                    return <Column
                    key={eachKey}
                    field={eachKey}
                    style={{textAlign: 'right'}}
                    body={renderPrice}
                  />
                  } else {
                    return <Column
                  key={eachKey}
                  field={eachKey}
                />
                  }
                } 
              })}
          </DataTable>
        ) : null}
      </Dialog>
{/* u verzendt het formulier */}

        <Dialog
        header="U verzendt het formulier"
        visible={submitDialogVisible}
        style={{ width: "45vw" }}
        maximizable
        modal
        onHide={() => setSubmitDialogVisiblee(false)}
      >
        <div className="card" style={{ width: "100%" }}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "50%" }}>U verzendt het formulier?</div>
            <div style={{ width: "50%" }}>
              <Button
                style={{ backgroundColor: "#44772c" }}
                onClick={() => {
                  setSaveDialogVisible(false);
                }}
              >
                Annuleren
              </Button>
            </div>
          </div>

          <br />
          <div style={{ display: "flex" }}>
            <div style={{ width: "50%" }}>
              
            </div>
            <div style={{ width: "50%" }}>
              <Button
                style={{ backgroundColor: "#44772c" }}
                onClick={_sendForm}
              >
                Indienen
              </Button>
            </div>
          </div>
        </div>
      </Dialog>


      <Dialog
        header="Waarschuwing"
        visible={warningDialogVisible}
        style={{ width: "45vw" }}
        maximizable
        modal
        onHide={() => setWarningDialogVisible(false)}
      >
        <div className="card" style={{ width: "100%" }}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "50%" }}>{message}</div>
            <div style={{ width: "50%" }}>
              <Button
                style={{ backgroundColor: "#44772c" }}
                onClick={() => {
                  setWarningDialogVisible(false);
                }}
              >
                OK
              </Button>
            </div>
          </div>

       
        </div>
      </Dialog>


      <Dialog
        header="Opslaan"
        visible={saveDialogVisible}
        style={{ width: "45vw" }}
        maximizable
        modal
        onHide={() => setSaveDialogVisible(false)}
      >
        <div className="card" style={{ width: "100%" }}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "50%" }}>Het formulier is opgeslagen.</div>
            <div style={{ width: "50%" }}>
              <Button
                style={{ backgroundColor: "#44772c" }}
                onClick={(e:any) => {
                  setSaveDialogVisible(false);
                  _executeSave('without-email');
                }}
              >
                OK
              </Button>
            </div>
          </div>

          <br />
          <div style={{ display: "flex" }}>
            <div style={{ width: "50%" }}>
              U kunt een unieke link te ontvangen waarmee het formulier later
              afgerond kunt worden.
            </div>
            <div style={{ width: "50%" }}>
              <Button
                style={{ backgroundColor: "#44772c" }}
                onClick={()=>{
                  _executeSave('with-email')
                }}
              >
                Versturen
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default FormController;
