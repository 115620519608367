import React, { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import SignatureCanvas from "react-signature-canvas";
import FormControllerService from "../services/form-controller.service";
import { InputTextarea } from "primereact/inputtextarea";
import { InputMask } from "primereact/inputmask";

type Props = {
  formControllerService: FormControllerService; // this service will manage all values from the inputs
  _sendFormCallback: any;
};

function FormSectionSix(props: Props) {
  const [dataURL, setDataURL] = React.useState<string | null>(null);
  const [_currentFormData, setCurrentFormData] = useState<any>();
  const [focusedFields, setFocusedFields] = useState(new Set());
  const [hasSigned, setHasSigned] = useState(false);
  let padRef = React.useRef<SignatureCanvas>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const clear = () => {
    padRef.current?.clear();
    setDataURL(null);
    (props.formControllerService._session as any).directorSignature = '';
    setHasSigned(false);
  };
  const handleFocus = (field: any) => {
    setFocusedFields((prev) => new Set(prev).add(field));
  };

  const setSign = () => {

    (props.formControllerService._session as any).directorSignature =
      padRef.current?.getTrimmedCanvas().toDataURL("image/png") as string;
      setHasSigned(true);
      // console.log(props.formControllerService._session?.directorSignature)
  };

  useEffect(() => {
    setCurrentFormData({
      ...props.formControllerService._formData
    });
    const y = props.formControllerService.calculateFacilitiesCosts();

    if (props.formControllerService._session?.directorSignature !== "") {
      setDataURL(
        props.formControllerService._session?.directorSignature as string
      );
    }

    if((props.formControllerService._READ_ONLY && props.formControllerService._CURRENT_ROLE !== 'ADMIN') || props.formControllerService.getSession()?.status == 'COMPLETED_APPROVED'){
      setIsDisabled(true)
    }
  }, []);

  const _globalFieldChangeHandler = (e: any) => {
    // get value and id of changing element
    const _id = e.target.id || e.id;
    let _value = e.target.value || e.value;
    if (e.hasOwnProperty("checked")) {
      _value = e.checked;
    }

    props.formControllerService.validateField(_id, _value);
    props.formControllerService.setFormData(_id as any, _value);
    setCurrentFormData({
      ..._currentFormData,
      [_id]: _value,
    });
  };
  const inputRef = useRef(null);
  const [inputWidth, setInputWidth] = useState(0);

  useEffect(() => {
      if (inputRef.current) {
          setInputWidth((inputRef.current as any).offsetWidth as number);
      }
  }, []);

  return (
    <div style={{ width: "100%", padding: 15, height: "100%" }}>
      <div className="p-fluid">
        <h3>Goedkeuring Uitvaartonderneming</h3>
        <div className="p-grid">
          <div className="p-col-12 p-md-6">
            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "45%" }}>
                <label htmlFor="uitvaart_nummer">Uitvaart nummer:</label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                  disabled
                  id="uitvaart_nummer"
                  value={_currentFormData?.uitvaart_nummer as any}
                  onFocus={() => handleFocus("uitvaart_nummer")}
                />
              </div>
              <div style={{ width: "10%", margin: "auto" }}></div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "45%" }}>
                <label htmlFor="subtotaal_bedrag_begraven">
                  Kosten Begraven:
                </label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                ref={inputRef}
                  disabled
                  id="subtotaal_bedrag_begraven"
                  onChange={_globalFieldChangeHandler}
                  value={props.formControllerService._burialCostTotal as any}
                  onFocus={() => handleFocus("subtotaal_bedrag_begraven")}
                />
              </div>
              <div style={{ width: "10%", margin: "auto" }}></div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "45%" }}>
                <label htmlFor="subtotaal_bedrag_faciliteiten">
                  Kosten Faciliteiten:
                </label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                  disabled
                  id="subtotaal_bedrag_faciliteiten"
                  onChange={_globalFieldChangeHandler}
                  value={
                    props.formControllerService
                      ._facilitiesTotalcostAmountFormatted as any
                  }
                  onFocus={() => handleFocus("subtotaal_bedrag_faciliteiten")}
                />
              </div>
              <div style={{ width: "10%", margin: "auto" }}></div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "45%" }}>
                <label htmlFor="totaal_bedrag">Totaal:</label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                  disabled
                  id="totaal_bedrag"
                  onChange={_globalFieldChangeHandler}
                  value={
                    props.formControllerService._totalCostsFormatted as any
                  }
                  onFocus={() => handleFocus("totaal_bedrag")}
                />
              </div>
              <div style={{ width: "10%", margin: "auto" }}></div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "45%" }}>
                <label htmlFor="toelichting_uitvaartonderneming">
                  Toelichting / Bijzonderheden:
                </label>
              </div>
              <div style={{ width: "40%" }}>
                <InputTextarea
                disabled={isDisabled}
                  id="toelichting_uitvaartonderneming"
                  onChange={_globalFieldChangeHandler}
                  value={
                    _currentFormData?.toelichting_uitvaartonderneming as any
                  }
                  onFocus={() => handleFocus("toelichting_uitvaartonderneming")}
                />
              </div>
              <div style={{ width: "10%", margin: "auto" }}></div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "45%" }}>
                <label htmlFor="voor_en_achternaam_ondertekening_uitvaartonderneming">
                  Voor- en achternaam:
                </label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                disabled={isDisabled}
                  id="voor_en_achternaam_ondertekening_uitvaartonderneming"
                  onChange={_globalFieldChangeHandler}
                  value={
                    _currentFormData?.voor_en_achternaam_ondertekening_uitvaartonderneming as any
                  }
                  onFocus={() =>
                    handleFocus(
                      "voor_en_achternaam_ondertekening_uitvaartonderneming"
                    )
                  }
                />
              </div>
              <div style={{ width: "10%", margin: "auto" }}>
                {(focusedFields.has(
                  "voor_en_achternaam_ondertekening_uitvaartonderneming"
                ) ||
                  _currentFormData?.voor_en_achternaam_ondertekening_uitvaartonderneming) && (
                  <i
                    className={`pi ${
                      props.formControllerService.validateField(
                        "voor_en_achternaam_ondertekening_uitvaartonderneming",
                        _currentFormData?.voor_en_achternaam_ondertekening_uitvaartonderneming
                      )
                        ? "pi-check green"
                        : "pi-times red"
                    }`}
                  />
                )}
              </div>
            </div>
            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "45%" }}>
                <label htmlFor="datum_ondertekening_uitvaartonderneming">
                  Datum van ondertekening:
                </label>
              </div>
              <div style={{ width: "40%" }}>
                <InputMask
                disabled={isDisabled}
                  mask="99-99-9999"
                  id="datum_ondertekening_uitvaartonderneming"
                  onChange={_globalFieldChangeHandler}
                  value={
              
                      _currentFormData?.datum_ondertekening_uitvaartonderneming
                  
                  }
                  onFocus={() =>
                    handleFocus("datum_ondertekening_uitvaartonderneming")
                  }
                />
              </div>
              <div style={{ width: "10%", margin: "auto" }}>
                {(focusedFields.has(
                  "datum_ondertekening_uitvaartonderneming"
                ) ||
                  _currentFormData?.datum_ondertekening_uitvaartonderneming) && (
                  <i
                    className={`pi ${
                      props.formControllerService.validateField(
                        "datum_ondertekening_uitvaartonderneming",
                        _currentFormData?.datum_ondertekening_uitvaartonderneming
                      )
                        ? "pi-check green"
                        : "pi-times red"
                    }`}
                  />
                )}
              </div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "45%" }}>
                <label htmlFor="digitale_handtekening_uitvaartonderneming">
                  Ondertekening:
                </label>
              </div>
              <div style={{ width: "40%" }}>
                <div>
                  <div style={{ width: "100%" }}>
                    {dataURL ? (
                      <img
                        className="sigImage"
                        src={dataURL}
                        style={{ width: "100%", border: '1px solid #c1c1c1' }}
                        alt="user generated signature"
                      />
                    ) : (<>
                      <div style={{ width: "100%", border: '1px solid #c1c1c1' }}>
                        <SignatureCanvas
                          ref={padRef}
                          canvasProps={{
                            width: inputWidth,
                            height: 200,
                            className: "sigCanvas",
                          }}
                        />
                      </div>
                    </>
                    )}
                  </div>

                  <div style={{ width: "100%" }}>
                    <Button
                    disabled={isDisabled}
                      className="p-button-secondary mb-2"
                      onClick={clear}
                      style={{ width: "100%" }}
                    >
                      Opnieuw instellen
                    </Button>
                    <Button
                    disabled={isDisabled}
                      className="p-button-primary"
                      onClick={setSign}
                      style={{ width: "100%" }}
                    >
                      Bevestig de handtekening
                    </Button>
                  </div>
                </div>
         
              </div>
              <div style={{ width: "10%", margin: "auto" }}></div>
            </div>

            <div style={{ display: "flex" }} className="padding">
             
            </div>

            <div style={{ width: "10%", margin: "auto" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormSectionSix;
