import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Panel } from "primereact/panel";
import FormControllerService, { Facility } from "../../services/form-controller.service";
import { Message } from "primereact/message";
import { Chip } from "primereact/chip";
import { InputMask } from "primereact/inputmask";

export interface ControlThreeProps {
  label: string;
  fieldName: string;
  description: string;
  dateFieldName: string;
  toTimeField: string;
  fromTimeField: string;
  _globalFieldChangeHandler: (e: any) => void;
  _currentFormData?: any;
  _facility?: Facility;
  isHoliday?: boolean;
  _formControllerService: FormControllerService
  _costCallback: any;
  _disabled: boolean

}

const ControlThree: React.FC<ControlThreeProps> = ({
  label,
  fieldName,
  toTimeField,
  fromTimeField,
  description,
  _globalFieldChangeHandler,
  dateFieldName,
  _currentFormData,
  _facility,
  _disabled,
  _formControllerService,
  _costCallback
}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [_priceUnit, setPriceUnit] = useState<any>("");
  const [_message, setMessage] = useState<any>("");
  const [messageType, setMessageType] = useState<"error" | "info" | "warn">(
    "info"
  );
  const [_price, setPrice] = useState<any>(0);
  const [_priceTotal, setPriceTotal] = useState<any>("€ 0,00");

  function parseDateFromEUString(input: string) {
    const parts = input.split("-");
    return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
  }
  function countFifteenMinuteSegments(start: Date, end: Date): number {
    const millisecondsInFifteenMinutes = 15 * 60 * 1000;
  
    // Calculate the difference in milliseconds between the two dates
    const differenceInMilliseconds = end.getTime() - start.getTime();
  
    // Calculate the number of 15-minute segments
    const numberOfSegments = Math.floor(differenceInMilliseconds / millisecondsInFifteenMinutes);
  
    return numberOfSegments;
  }

  const setTime = (time: string, date: Date) => {
    const [hours, minutes] = time.split(':').map(Number);
    const newDate = new Date(date);
    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    return newDate
  }

  const _calculateCosts = () => {
    const _relatedFacility = _facility
    let _max = 0;
    if (isEnabled && _data[dateFieldName] &&  _data[fromTimeField] && _data[toTimeField]) {
      let _totalPrice = 0;
      const _date = parseDateFromEUString(_data[dateFieldName]);
      const _fromDate = setTime(_data[fromTimeField], _date);
      const _toDate = setTime(_data[toTimeField], _date);

      // check generically if both dates are valid
      if(isNaN(_fromDate.getTime()) || isNaN(_date.getTime()) || isNaN(_toDate.getTime())){
        _costCallback({
          name: _relatedFacility?.facility,
          category: _relatedFacility?.category,
          selected: true,
          valid: false
        })

        return
      }


      const _baseCost = Number(_relatedFacility?.price) as number;
      const _extraCost = Number(_relatedFacility?.extra) as number;
      _totalPrice = _totalPrice + _baseCost;


      if(_formControllerService.checkForSaturday(_fromDate)){
        _totalPrice = _totalPrice + Number(_relatedFacility?.surcharge)
      }


      if(_relatedFacility?.hasOwnProperty('max')){
        _max = _relatedFacility?.max
      } else {
        _max = 99999999
      }


      const _segments = countFifteenMinuteSegments(_fromDate, _toDate);

      if(_segments > _max){
        setMessageType('info');
        setMessage('Max. kwartalen overschreden. Er zijn extra kosten van toepassing.');
        let _diff = _segments - _max

        console.log(_diff);
        _diff = _diff * _extraCost;
        console.log(_diff);
        _totalPrice = _totalPrice + _diff;

      } else {
        setMessage('');
      }
      setPriceTotal(`€ ${_totalPrice},00`);
      _costCallback({
        name: _relatedFacility?.facility,
        category: _relatedFacility?.category,
        selectedOption: '',
        selected: true,
        relatedDateAndTimes: {date: _data[dateFieldName], from: _data[fromTimeField], to: _data[toTimeField]},
        totalCost: _totalPrice,
        valid: true
      })



    } else {
      _costCallback({
        name: _relatedFacility?.facility,
        category: _relatedFacility?.category,
        selectedOption: '',
        selected: false,
        valid: true
      })
      setPriceTotal(`€ 0,00`);
    }
  };

  const [_data, setData] = useState<any>({
    date: new Date(),
    fromTime: null,
    toTime: null,
  });

  useEffect(() => {
    _calculateCosts();
  }, [_data]);

  const handleCheckboxChange = (e: any) => {
    setIsEnabled(e.target.checked);
    setMessage('');
    _globalFieldChangeHandler(e);
    if(!e.target.checked){
     setData({
      ..._data,
      [dateFieldName]:'',
      [fromTimeField]: '',
      [toTimeField]: ''
     })
    } else {

    }
  };

  const _handleChange = (e: any) => {
    const { id, value } = e.target;
    if(id == dateFieldName){
      setData({ ..._data,
        [dateFieldName]: value
       });
      
    } else {
      setData({ ..._data, [id]: value });
    }
    _globalFieldChangeHandler(e);
  };


  useEffect(() => {
    if (_currentFormData) {
      setData(_currentFormData);
      setIsEnabled(_currentFormData[fieldName]);
    }
  }, []);

  const renderHeader = () => {
    return (
      <>
        <Checkbox
        disabled={_disabled}
          checked={isEnabled}
          id={`${fieldName}`}
          onChange={handleCheckboxChange}
          style={{ marginRight: "1rem" }}
        />
        &nbsp;&nbsp;{label}&nbsp;&nbsp;
        {_message ? <Message severity={messageType} text={_message} /> : null}
      </>
    );
  };

  return (
    <Panel header={renderHeader()}>
      <div style={{ display: "flex", marginBottom: "1rem" }}>
        
        <div style={{ width: "40%" }}>
          <label style={{ marginTop: 6 }}>{label}:</label>
          <p>{description}</p>
         
        </div>
        <div style={{ width: "40%", display: 'flex' }}>
          <div style={{width: '100%'}}>
            <InputMask
              id={dateFieldName}
              onChange={_handleChange}
              mask="99-99-9999"
              value={_data[dateFieldName]}
              disabled={!isEnabled || _disabled}
            />
           {isEnabled && (
        <div>
          <br />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
           
            <div style={{ width: "40%" }}>
              <InputMask
                id={fromTimeField}
                placeholder="Van"
                mask="99:99"
                disabled={!isEnabled || _disabled}
                onChange={_handleChange}
                value={
                 _data[fromTimeField]
                }
              />
            </div>
          </div>
          <br />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
          
            <div style={{ width: "40%" }}>
              <InputMask
                id={toTimeField}
                placeholder='Tot'
                mask="99:99"
                disabled={!isEnabled || _disabled}
                onChange={_handleChange}
                value={_data[toTimeField]}
              />
            </div>
          </div>
        </div>
            )}
          </div>

     
        </div>
        <div style={{ width: "20%" }}>
        <h5>{_priceTotal}</h5>
      </div>

      </div>
    </Panel>
  );
};

export default ControlThree;
