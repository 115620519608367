
const AppFooter = () => {
    return (
      <div className='footerPanel'>
        <div id="footerBanner" style={{minHeight: 75}}>

        </div>

        <div className="mainFooterContainer">

        </div>

      </div>
  )
  }
  
  export default AppFooter;