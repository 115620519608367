
//#region  CSS/SCSS
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'remixicon/fonts/remixicon.css';
import './App.css';
import AppHeader from './components/header';
import AppFooter from './components/footer';
import FormController from './components/form-controller';
function App() {
  return (
    <div className="app">
      <AppHeader />
      <div className='mainAppContainer'>
        <FormController />
      </div>
      <AppFooter />
    </div>
  );
}

export default App;
