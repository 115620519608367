import React, { useEffect, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Facility } from '../../services/form-controller.service';
import { Chip } from 'primereact/chip';

interface FormProps {
  label: string;
  fieldName: string;
  _globalFieldChangeHandler?: (e: any) => void;
  _currentFormData?: any;
  _facility?: Facility;
  _costCallback: any;
  _disabled: boolean;

}

const ControlFour: React.FC<FormProps> = ({
  label,
  fieldName,
  _currentFormData,
  _globalFieldChangeHandler = () => {},
  _facility,
  _costCallback,
  _disabled
}) => {

  const [onOff, setOnOff] = useState<boolean>(false);
  const [_data, setData] = useState<any>({});
  const [_relatedFacility, setRelatedFacility] = useState<any>();
  const [_price, setPrice] = useState<any>(0);
  const [_priceTotal, setPriceTotal] = useState<any>("€ 0,00");


  useEffect(()=>{
    if(_currentFormData){
      setData(_currentFormData)
      setOnOff(_currentFormData[fieldName])

    }

   
  }, [])

  useEffect(() => {
    _calculateCosts();
  }, [_data]);



  const _calculateCosts = () => {
    if (onOff) {
      if(_facility?.hasOwnProperty('price')){
        setPriceTotal(`€ ${_facility?.price},00`);
        _costCallback({
          selected: true,
          name: _facility?.facility,
          category: _facility?.category,
          selectedOption: '',
          totalCost: Number(_facility?.price),
          valid: true
        })
      } else {
    
      }
    } else {
      _costCallback({
        selected: false,
        name: _facility?.facility,
        category: _facility?.category,
        selectedOption: '',
        totalCost: Number(_facility?.price),
        valid: true
      })
      setPriceTotal(`€ 0,00`);
    }
  };
  const handleChange = (e:any)=>{

    setOnOff(e.target.checked)
    setData({..._data, [fieldName]: e.checked})
    _globalFieldChangeHandler(e);
    
  }
  return (
    <div style={{ display: 'flex', marginBottom: '1rem', alignItems: 'center' }}>
      <div style={{ width: '40%' }}>
      <Checkbox
          id={fieldName}
          disabled={_disabled}
          onChange={handleChange}
          checked={onOff as boolean}
        />&nbsp;&nbsp;<label htmlFor={fieldName}>{label}:</label>
      </div>
      <div style={{ width: '40%' }}>
        
      </div>
      <div style={{ width: "20%", display: `${_facility?.hasOwnProperty('price') ? 'flex' : 'none' }` }}>

        <h5>{_priceTotal}</h5>
        </div>
    </div>
  );
};

export default ControlFour;
