import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Panel } from "primereact/panel";
import { Facility } from "../../services/form-controller.service";
import { Chip } from "primereact/chip";
import { Message } from "primereact/message";
import { isUint16Array } from "util/types";
import { InputMask } from "primereact/inputmask";

interface DropdownOption {
  label: string;
  value: any;
}

export interface ControlOneProps {
  dropdownOptions: DropdownOption[];
  dropdownLabel: string;
  fieldName: string;
  fromDateFieldName: string;
  toDateFieldName: string;
  _globalFieldChangeHandler: (e: any) => void;
  _currentFormData?: any;
  _facility: Facility[];
  _costCallback: any;
  _disabled: boolean;
}

const ControlOne: React.FC<ControlOneProps> = ({
  dropdownOptions,
  dropdownLabel,
  fieldName,
  fromDateFieldName,
  toDateFieldName,
  _globalFieldChangeHandler,
  _currentFormData,
  _facility,
  _costCallback,
  _disabled,
}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [_data, setData] = useState<any>({});
  const [_priceUnit, setPriceUnit] = useState<any>("");
  const [_message, setMessage] = useState<any>("");
  const [messageType, setMessageType] = useState<"error" | "info" | "warn">(
    "info"
  );
  const [_max, setMax] = useState<any>(0);
  const [_price, setPrice] = useState<any>(0);
  const [_priceTotal, setPriceTotal] = useState<any>("€ 0,00");

  const handleCheckboxChange = (e: any) => {
    setIsEnabled(e.target.checked);
    _globalFieldChangeHandler(e);
    if (e.target.checked && _data.hasOwnProperty(dropdownLabel) && _data[fromDateFieldName] != undefined && _data[toDateFieldName] != undefined) {
      _calculateCosts(e.target.checked);
      

    } else {
      setPriceTotal("€ 0,00");
      setData({
        ..._data,
        [dropdownLabel]: "",
        [fromDateFieldName]: "",
        [toDateFieldName]: "",
      });
    }
  };

  const calculateDaysBetweenDates = (
    startDate: Date,
    endDate: Date
  ): number => {
    // Calculate the difference in time (milliseconds) between the two dates
    const timeDifference = endDate.getTime() - startDate.getTime();

    // Convert the time difference from milliseconds to days
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    // Return the absolute value of the difference in days
    return Math.abs(daysDifference);
  };
  function parseDateFromEUString(input: string) {
    const parts = input.split("-");
    return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
  }
  const _handleChange = (e: any) => {
    setData({ ..._data, [e.target.id]: e.target.value });
    _globalFieldChangeHandler(e);
  };

  useEffect(() => {
    _calculateCosts();
  }, [_data]);

  const _calculateCosts = (mode: boolean = true) => {
    // handle incomplete values
    if (
      _data[dropdownLabel] != undefined &&
      _data[fromDateFieldName] &&
      _data[toDateFieldName]
    ) {
      let _totalCost = 0;
      const _selectedOpt = _data[dropdownLabel];
      const _relatedFacility = _facility?.filter((eachOne) => {
        return eachOne.option == _selectedOpt;
      })[0];
      const _fromDate = parseDateFromEUString(_data[fromDateFieldName]);
      const _toDate = parseDateFromEUString(_data[toDateFieldName]);
      if (isNaN(_fromDate.getTime()) || isNaN(_toDate.getTime())) {
        return;
      }
      setMax(_relatedFacility?.max);
      let daysTotal = 0;
      if (!isNaN(_fromDate.getTime()) && !isNaN(_toDate.getTime())) {
        daysTotal = calculateDaysBetweenDates(_fromDate, _toDate);
        if (daysTotal > (_relatedFacility?.max as any)) {
          setMessageType("error");
          setMessage("Max. aantal dagen overschreden.");
        } else {
          setMessageType("info");
          setMessage("");
        }
      }

      if (_relatedFacility) {
        if (_relatedFacility.price_unit == "vast") {
          // check for max
          setPriceTotal(`€ ${_relatedFacility.price},00`);
          _totalCost = _relatedFacility.price;
        } else if (_relatedFacility.price_unit == "per dag") {
          const cost = daysTotal * Number(_relatedFacility.price);
          setPriceTotal(`€ ${cost},00`);
          _totalCost = cost;
        }
      }

      if (!mode) {
        _costCallback({
          selected: false,
          name: _relatedFacility?.facility,
          category: _relatedFacility?.category,
          selectedOption: "",
          relatedDates: {
            from: _data[fromDateFieldName],
            to: _data[toDateFieldName],
          },
          valid:
            !isNaN(
              parseDateFromEUString(_data[fromDateFieldName]).getMilliseconds()
            ) &&
            !isNaN(
              parseDateFromEUString(_data[toDateFieldName]).getMilliseconds()
            ),
        });
      } else {
        _costCallback({
          selected: true,
          name: _relatedFacility?.facility,
          category: _relatedFacility?.category,
          selectedOption: _selectedOpt,
          relatedDates: {
            from: _data[fromDateFieldName],
            to: _data[toDateFieldName],
          },
          totalCost: Number(_totalCost),
          valid:
            !isNaN(
              parseDateFromEUString(_data[fromDateFieldName]).getMilliseconds()
            ) &&
            !isNaN(
              parseDateFromEUString(_data[toDateFieldName]).getMilliseconds()
            ),
        });
      }
    } else {
      _costCallback({
        selected: false,
        name: fieldName,
        category: _facility[0].category,
        selectedOption: "",
        totalCost: 0,
        valid: false,
      });
    }
  };

  useEffect(() => {
    if (_currentFormData) {
      if (_currentFormData[fieldName] !== undefined) {
        setIsEnabled(_currentFormData[fieldName]);
      }
      setData({
        ..._currentFormData,
        [fromDateFieldName]: _currentFormData[fromDateFieldName],
        [toDateFieldName]: _currentFormData[toDateFieldName],
      });
      _calculateCosts();
    }
    if (_disabled) {
      setIsEnabled(false);
    }
  }, []);

  const renderHeader = () => {
    return (
      <>
        <Checkbox
          disabled={_disabled}
          checked={isEnabled}
          id={`${fieldName}`}
          onChange={handleCheckboxChange}
          style={{ marginRight: "1rem" }}
        />
        &nbsp;&nbsp;{fieldName} &nbsp;&nbsp;
        {_message ? <Message severity={messageType} text={_message} /> : null}
      </>
    );
  };
  return (
    <Panel header={renderHeader()}>
      <div
        style={{ display: "flex", marginBottom: "1rem", alignItems: "center" }}
      >
        <div style={{ width: "40%" }}>
          <label htmlFor={dropdownLabel} className="dropdown-label">
            {fieldName}:
          </label>
        </div>

        <div style={{ width: "40%" }}>
          <Dropdown
            id={dropdownLabel}
            options={dropdownOptions}
            onChange={_handleChange}
            value={_data[dropdownLabel]}
            disabled={!isEnabled}
          />
        </div>

        <div style={{ width: "20%" }}>
          <h5>{_priceTotal}</h5>
        </div>
      </div>

      <div style={{ display: "flex", marginBottom: "1rem" }}>
        <div style={{ width: "40%" }}>
          <label htmlFor="date_from">Datum van:</label>
        </div>
        <div style={{ width: "40%" }}>
          <InputMask
            mask="99-99-9999"
            id={fromDateFieldName}
            onChange={_handleChange}
            value={_data[fromDateFieldName]}
            disabled={!isEnabled}
          />
        </div>
      </div>

      <div style={{ display: "flex", marginBottom: "1rem" }}>
        <div style={{ width: "40%" }}>
          <label htmlFor="date_to">Datum tot:</label>
        </div>
        <div style={{ width: "40%" }}>
          <InputMask
            id={toDateFieldName}
            mask="99-99-9999"
            onChange={_handleChange}
            value={_data[toDateFieldName]}
            disabled={!isEnabled}
          />
        </div>
      </div>
    </Panel>
  );
};

export default ControlOne;
