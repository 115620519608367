import React, { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Editor } from "primereact/editor";
import SignaturePad from "react-signature-canvas";
import SignatureCanvas from "react-signature-canvas";
import FormControllerService from "../services/form-controller.service";
import { InputTextarea } from "primereact/inputtextarea";

type Props = {
  formControllerService: FormControllerService;
  _approveFormCallback: any
};

function FormSectionSeven(props: Props) {
  const [dataURL, setDataURL] = React.useState<string | null>(null);
  const [_currentFormData, setCurrentFormData] = useState<any>();
  const [focusedFields, setFocusedFields] = useState(new Set());
  let padRef = React.useRef<SignatureCanvas>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  const clear = () => {
    padRef.current?.clear();
    setDataURL(null)
  };
  const handleFocus = (field: any) => {
    setFocusedFields((prev) => new Set(prev).add(field));
  };


  const setSign = () => {
    (props.formControllerService.getSession() as any).adminSignature = padRef.current?.getTrimmedCanvas().toDataURL("image/png") as string
  }
  useEffect(() => {
    setCurrentFormData(props.formControllerService._formData);
    if(props.formControllerService._session?.adminSignature !== ''){
      setDataURL(props.formControllerService._session?.adminSignature as string)
    }

    if((props.formControllerService._READ_ONLY && props.formControllerService._CURRENT_ROLE !== 'ADMIN') || props.formControllerService.getSession()?.status == 'COMPLETED_APPROVED'){
      setIsDisabled(true)
    }
  }, []);

  const _globalFieldChangeHandler = (e: any) => {
    // get value and id of changing element
    const _id = e.target.id || e.id;
    let _value = e.target.value || e.value;
    if (e.hasOwnProperty("checked")) {
      _value = e.checked;
    }

    props.formControllerService.validateField(_id, _value);
    props.formControllerService.setFormData(_id as any, _value);
    setCurrentFormData({
      ..._currentFormData,
      [_id]: _value,
    });
  };

  const inputRef = useRef(null);
  const [inputWidth, setInputWidth] = useState(0);

  useEffect(() => {
      if (inputRef.current) {
          setInputWidth((inputRef.current as any).offsetWidth as number);
      }
  }, []);

  return (
    <div style={{ width: "100%", padding: 15, height: "100%" }}>
      <div className="p-fluid">
        <h3>Goedkeuring Beheerder</h3>
        <div className="p-grid">
          <div className="p-col-12 p-md-6">
            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "45%" }}>
                <label htmlFor="uitvaart_nummer">Uitvaart nummer:</label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                  disabled
                  id="uitvaart_nummer"
                  value={_currentFormData?.uitvaart_nummer as any}
                  onFocus={() => handleFocus("uitvaart_nummer")}
                />
              </div>
              <div style={{ width: "10%", margin: "auto" }}></div>
            </div>



            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "45%" }}>
                <label htmlFor="voor_en_achternaam_beheerder">
                  Voor- en achternaam:
                </label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                ref={inputRef}
                disabled={isDisabled}
                  id="voor_en_achternaam_beheerder"
                  onChange={_globalFieldChangeHandler}
                  value={
                    _currentFormData?.voor_en_achternaam_beheerder as any
                  }
                  onFocus={() =>
                    handleFocus(
                      "voor_en_achternaam_beheerder"
                    )
                  }
                />
              </div>
              <div style={{ width: "10%", margin: "auto" }}>
                {(focusedFields.has(
                  "voor_en_achternaam_beheerder"
                ) ||
                  _currentFormData?.voor_en_achternaam_beheerder) && (
                  <i
                    className={`pi ${
                      props.formControllerService.validateField(
                        "voor_en_achternaam_beheerder",
                        _currentFormData?.voor_en_achternaam_beheerder
                      )
                        ? "pi-check green"
                        : "pi-times red"
                    }`}
                  />
                )}
              </div>
            </div>

            <div style={{ display: "flex" }} className="padding">
  <div style={{ width: "45%" }}>
    <label htmlFor="digitale_handtekening_beheerder">
      Ondertekening:
    </label>
  </div>
  <div style={{ width: "40%" }}>
    <div>
      <div style={{ width: "100%" }}>
        {dataURL ? (
          <img
          style={{width: "100%", border: '1px solid #c1c1c1'}}
            className="sigImage"
            src={dataURL}
            alt="user generated signature"
          />
        ) : (
          <div style={{ width: "100%", border: '1px solid #c1c1c1' }}>
          <SignaturePad
          canvasProps={{ width: inputWidth, height: 200, className: 'sigCanvas' }}

            backgroundColor="rgb(115, 189, 247)"
            ref={padRef}
          />
          </div>
        )}
      </div>
      <div style={{ width: "100%" }}>
        <Button 
        // disabled={isDisabled}
          className="p-button-secondary" 
          onClick={clear}
          style={{ width: "100%", marginBottom: 10 }}
        >
          Opnieuw instellen
        </Button>
        <Button 
        // disabled={isDisabled}
          className="p-button-primary" 
          onClick={setSign}
          style={{ width: "100%" }}
        >
          Bevestig de handtekening
        </Button>
      </div>
    </div>


  </div>
  <div style={{ width: "10%", margin: "auto" }}>
   
  </div>
</div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormSectionSeven;
