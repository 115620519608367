import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import FormControllerService from "../services/form-controller.service";

interface SectionTwoProps {
  formControllerService: FormControllerService; // this service will manage all values from the inputs
}
const FormSectionTwo = (props: SectionTwoProps) => {
  const _costs = props.formControllerService.calculateBurialCosts();
  const _col: any[] = [
    {
      field: "cost_description",
      header: "",
    },
    {
      field: "type",
      header: "",
    },
    {
      field: "years",
      header: "",
    },
    {
      field: "cost",
      header: "",
    },
  ];

  const getRowClass = (rowData: any) => {
    return rowData.type === "Subtotaal" ? "bold-row" : "";
  };

  const formatCost = (data: any) => {
    return `${data.cost.replaceAll(' ', '.')}`
  }
  return (
    <div style={{ width: "100%", padding: 15, height: "100%" }}>
      <div className="p-fluid">
        <h3>Kosten Begraven</h3>
        <DataTable value={_costs} rowClassName={getRowClass}>
          {_col.map((eachKey) => {
            if (eachKey.field == "cost") {
              return (
                <Column
                  key={eachKey.field}
                  field={eachKey.field}
                  style={{ textAlign: "right" }}
                  body={formatCost}
                />
              );
            } else {
              return <Column key={eachKey.field} field={eachKey.field} />;
            }
          })}
        </DataTable>
      </div>
    </div>
  );
};

export default FormSectionTwo;
